/***** GLOBAL STYLES *****/

body {
	font-family: 'Space Mono', monospace;
	margin: 0;
	padding: 0;
  }
  
  a {
	text-decoration: none;
  }
  
  li {
	list-style: square;
  }
  
  button {
	font-size: 100%;
	font-family: inherit;
	border: 0;
	padding: 0;
	background-color: inherit;
  }
  
  /***** MAPBOX STYLES *****/
  
  .mapContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
  }
  
  .mapboxgl-popup {
	max-width: 200px;
  }
  
  .mapboxgl-popup-content {
	text-align: center;
	font-family: "Open Sans", sans-serif;
  }
  
  .mapboxgl-ctrl-bottom-left {
	bottom: 50px;
  }
  
  /***** POPUP INFO *****/
  
  .popupContainer {
	position: fixed;
	bottom: -500px;
	width: 100%;
	max-height: 500px;
	z-index: 3;
	transition: 0.3s;
	display: flex;
	justify-content: center;
	align-items: flex-end;
  }
  
  .popupContainer.is-open {
	bottom: 0 !important;
  }
  
  .popupInfo {
	width: 100%;
	max-height: 500px;
	overflow-y: auto;
	background-color: #fff;
  }
  
  .popup-image > img {
	object-fit: 200%;
  }
  
  .popupImage {
	background-image: url("../images/1793-town-of-york.jpg");
	height: 250px;
	width: 100%;
	position: relative;
	z-index: 1;
	left: 0;
	top: 0;
	background-size: 100%;
	background-position: 0% 100%;
  }
  
  /* Image source overlay */
  .imageSource {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 10px;
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 0.9em;
	text-align: left;
	box-sizing: border-box;
  }
  
  .imageSource a {
	color: #fff;
	text-decoration: underline;
  }
  
  .imageSource a:hover {
	color: #ddd;
  }
  
  .closeBtn {
	position: absolute;
	display: flex;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	background: #fff;
	z-index: 2;
	text-align: center;
	margin: 6px;
	padding: 6px;
	right: 0;
  }
  
  /* Animated background panning */
  @keyframes pan-image {
	0% {
	  background-position: 0% 0%;
	  background-size: 125%;
	}
	20% {
	  background-position: 30% 35%;
	  background-size: 125%;
	}
	20.0001% {
	  background-position: 60% 85%;
	  background-size: 150%;
	}
	40% {
	  background-position: 49% 81%;
	  background-size: 150%;
	}
	40.0001% {
	  background-position: 80% 42%;
	  background-size: 150%;
	}
	60% {
	  background-position: 84% 33%;
	  background-size: 150%;
	}
	60.0001% {
	  background-position: 0% 0%;
	  background-size: 125%;
	}
	80% {
	  background-position: 15% 4%;
	  background-size: 200%;
	}
	80.0001% {
	  background-position: 80% 10%;
	  background-size: 200%;
	}
	100% {
	  background-position: 72% 14%;
	  background-size: 200%;
	}
  }
  
  /* Top image ratio box trick */
  .top-image {
	position: relative;
	overflow: hidden;
  }
  .top-image img {
	width: 100%;
	height: auto;
	max-height: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
  }
  .top-image:after {
	content: "";
	display: block;
	padding-top: 25%;
  }
  
  /***** TIMELINE ITEMS *****/
  
  #timeline {
	background-color: #fff;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	z-index: 2;
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
  }
  
  #timeline p {
	margin: 5px 0 !important;
  }
  
  #currentYear,
  #timelineBtn {
	width: 20%;
	text-align: center;
  }
  
  .time-left,
  .time-right {
	float: left;
	width: 13.33%;
	cursor: pointer;
  }
  
  .time-mid {
	float: left;
	width: 20%;
  }
  
  /***** CATEGORIES *****/
  
  #categories {
	position: absolute;
	bottom: 60px;
	display: block;
	width: 100%;
	text-align: center;
	z-index: 2;
	overflow: auto;
	white-space: nowrap;
  }
  
  #categories i {
	padding: 0 12px 0 0;
  }
  
  .btn-warning {
	color: #fff !important;
  }
  .btn-warning:hover {
	color: #fff !important;
  }
  
  /***** TOP NAVIGATION *****/
  
  #nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: 100%;
	height: 50px;
	z-index: 3;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	position: relative;
  }
  
  .column {
	display: flex;
	align-items: center;
  }
  
  .left.column,
  .right.column {
	flex: 0 0 auto;
  }
  
  .middle.column {
	flex: 1 1 auto;
	justify-content: center;
  }
  
  #logo {
	font-size: 1.5em;
	text-align: center;
  }
  
  #logo h1 {
	margin: 0;
	font-size: 1.5rem;
	font-family: 'Space Mono', monospace;
  }
  #logo a {
	color: #000;
	text-decoration: none;
  }
  #logo a:hover {
	text-decoration: none;
  }
  
  .fb-link {
	text-decoration: none;
	color: inherit;
	font-size: 1.5rem;
	padding: 0 15px;
	cursor: pointer;
  }
  .fb-link:hover {
	color: #007bff; /* Optional hover color */
  }
  
  #menu {
	width: 50px;
	height: 50px;
  }
  
  #login {
	width: 100px;
	height: 50px;
	float: right;
  }
  
  /***** SIDENAV (MENU NAV) *****/
  
  .menuNav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	background-color: #fff;
	overflow-x: hidden;
	padding-top: 60px;
	transition: 0.5s;
	box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2);
  }
  
  .menuNav.is-open {
	width: 400px !important;
  }
  
  .menuNav a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	font-size: 18px;
	color: #818181;
	display: block;
	transition: 0.2s;
  }
  
  /***** HAMBURGERS *****/
  /* Source: Hamburgers by Jonathan Suh */
  
  .hamburger {
	padding: 11px 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.3s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
  }
  
  .hamburger-box {
	width: 25px;
	height: 20px;
	display: inline-block;
	position: relative;
  }
  
  .hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
	width: 20px;
	height: 2px;
	background-color: #000;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
	content: "";
	display: block;
  }
  .hamburger-inner::before {
	top: -5px;
  }
  .hamburger-inner::after {
	bottom: -5px;
  }
  
  /* Slider Reverse */
  .hamburger--slider-r .hamburger-inner {
	top: 7px;
  }
  .hamburger--slider-r .hamburger-inner::before {
	top: 7px;
	transition-property: transform, opacity;
	transition-timing-function: ease;
	transition-duration: 0.3s;
  }
  .hamburger--slider-r .hamburger-inner::after {
	top: 15px;
  }
  .hamburger--slider-r.is-active .hamburger-inner {
	transform: translate3d(0, 10px, 0) rotate(-45deg);
  }
  .hamburger--slider-r.is-active .hamburger-inner::before {
	transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
	opacity: 0;
  }
  .hamburger--slider-r.is-active .hamburger-inner::after {
	transform: translate3d(0, -15px, 0) rotate(90deg);
  }
  
  /* Minus */
  .hamburger--minus .hamburger-inner::before,
  .hamburger--minus .hamburger-inner::after {
	transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
  }
  .hamburger--minus.is-active .hamburger-inner::before,
  .hamburger--minus.is-active .hamburger-inner::after {
	opacity: 0;
	transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
  }
  .hamburger--minus.is-active .hamburger-inner::before {
	top: 0;
  }
  .hamburger--minus.is-active .hamburger-inner::after {
	bottom: 0;
  }
  
  /***** FEEDBACK FORM STYLES *****/
  
  .outer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none; /* Hidden by default */
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
  }
  .outer.is-open {
	display: flex; /* Shows the modal when is-open class is added */
  }
  
  .middleForm {
	width: 100%;
	max-width: 600px;
	padding: 0 15px;
  }
  
  .inner {
	position: relative;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	width: 100%;
	max-height: 700px;
	overflow: auto;
	box-sizing: border-box;
  }
  
  .close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	line-height: 1;
  }
  .close-button:hover {
	color: red;
  }
  
  .success-message {
	animation: fadeOut 3s forwards;
  }
  
  @keyframes fadeOut {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }
  
  .sources ol {
	list-style-type: decimal;
  }
  .sources ol li {
	margin-bottom: 0.5em;
	font-size: 12px;
  }
  
  /***** MEDIA QUERIES *****/
  
  /* Large screens */
  @media (min-width: 1200px) {
	.top-image:after {
	  padding-top: 50%;
	}
	.mapboxgl-ctrl-bottom-left {
	  bottom: 0;
	}
	.inner {
	  max-width: 800px;
	}
  
	.popupContainer {
	  background-color: #fff;
	  position: fixed;
	  top: 50px;
	  left: -500px;
	  display: block;
	  width: 400px;
	  max-height: 100%;
	  height: 100%;
	  z-index: 2;
	  transition: 0.2s;
	  overflow-x: hidden;
	}
	.popupContainer.is-open {
	  top: 50px;
	  left: 0;
	  max-height: 100% !important;
	  width: 400px !important;
	}
	.popupInfo {
	  max-height: 100%;
	  background-color: #fff;
	  overflow-y: auto;
	}
	.popupHeader {
	  height: 250px;
	}
  
	#timeline {
	  background-color: #fff !important;
	  position: absolute;
	  top: 62px;
	  left: 12px;
	  width: 375px;
	  height: 50px;
	  text-align: center;
	  z-index: 2;
	  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	}
  
	#nav {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  background-color: #fff;
	  width: 100%;
	  max-width: 400px; /* Keeps header at a fixed width if desired */
	  height: 50px;
	  z-index: 3;
	  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	  position: relative;
	}
  }
  
  /* Small screens */
  @media (max-width: 767px) {
	.inner {
	  padding: 15px;
	}
	.close-button {
	  font-size: 1.2rem;
	  top: 8px;
	  right: 8px;
	}
  }
  @media (max-width: 480px) {
	.inner {
		padding: 15px;
		max-height: 550px;
	  }
  }